@import '../../../ui/theme/breakpoints';
//noinspection All
.voucher-order-footer {
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem calc(1rem + constant(safe-area-inset-bottom, 0));
  padding: 1rem 2rem calc(1rem + env(safe-area-inset-bottom, 0));
  align-items: center;
  @include lg {
    max-width: #{$desktop-max-width};
    width: 100%;
    margin: 0 auto;
    background: none;
  }

  & > .date, & > .text {
    flex: 1;
    font-size: 18px;
    color: #000;
  }

  & > .next-container {
    flex: 1;

    ion-button {
      min-width: 6rem;
      width: 100%;
    }
  }

  .text + .next-container {
    flex: 0;
  }
  .originalPrice {
    text-decoration: line-through;
    color: var(--ion-color-tertiary);
    white-space: nowrap;
  }

  .discountedValue {
    color: var(--ion-color-secondary);
    font-weight: 600;
    white-space: nowrap;
  }
}
