@import '../../../ui/theme/breakpoints';

.expandableSelect {
  display: flex;
  flex-direction: column;

  select {
    display: none;
  }

  ion-item {
    cursor: pointer;

    &::part(native) {
      --background: var(--ion-color-light);
    }
  }

  .expandButton {
    height: 3rem;
    border: 1px solid var(--ion-color-tertiary);
    color: var(--ion-color-tertiary);
    background: var(--ion-color-tertiary-contrast);
    transition: all 0.25s ease-in-out;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    padding: 0.75rem 1rem;
    align-items: center;

    .textContainer {
      text-align: left;
      flex: 1;
    }

    svg {
      transform: rotate(0);
      transition: transform ease-in-out 0.25s;
      fill: var(--ion-color-tertiary);
      width: 1rem;
      height: 1rem;
      margin-left: 1rem;
      margin-right: 0.75rem;
    }

    &:focus {
      outline: none;
    }
  }

  .selectableHidden {
    opacity: 0;
    position: absolute;
    left: 200%;
    transition: none;
  }

  .selectableContainer {
    overflow: hidden;
    transition: max-height linear 0.25s;
    background: var(--ion-color-light);

    ion-label {
      color: var(--ion-color-tertiary);
    }
  }
}

.hasValue {
  .expandButton {
    background: var(--ion-color-linen);
    border: 1px solid var(--ion-color-linen);
    color: var(--ion-color-tertiary);
  }
}

.expanded {
  .selectable-container {
    overflow-y: auto;
    animation: hide-scroll 0.25s backwards;

    ion-label {
      color: var(--ion-color-tertiary);
    }

    @keyframes hide-scroll {
      from, to {
        overflow: hidden;
      }
    }

    &.select-length {
      max-height: 200px;
    }
  }

  .arrowContainer svg {
    transform: rotate(-90deg);
  }
}

.disabled {
  .expandButton {
    border: 1px solid var(--ion-color-medium);
    color: var(--ion-color-medium);
    cursor: default;
    background: transparent;
  }
}

.isWithIcon {
  ion-item {
    margin-top: 0.5rem;

    &::part(native) {
      padding: 0 2rem;
    }
  }
}

.sectionHeading {
  font-weight: 600;
  @include lg {
    font-size: 25px;
  }
}

.selectExpertHeading {
  font-weight: 600;
  margin-top: 0;
  @include lg {
    font-size: 25px;
  }
}

.headingWrapper {
  padding: 0 .6rem;
}

.searchError {
  font-size: 14px;
  color: #9C5239;
  display: flex;
  margin-top: 0.55rem;

  &::before {
    content: "";
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    background: url(../../theme/images/info.svg) no-repeat;
    flex-shrink: 0;
  }

  &.stretch {
    margin-top: -1.75rem;
  }
}

.groupWrapper {
  background-color: var(--ion-color-light2);
}

.groupHeader {
  font-weight: 600;
  padding: 0.925rem 1.125rem;
  font-size: 1.125rem;
  background-color: var(--ion-color-light);
}

.optionItem {
  padding: 0.75rem 1rem 0.75rem 2rem;
  &:hover {
    background-color: var(--ion-color-linen);
    cursor: pointer;
  }
}

.optionContent {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 0.5rem;
  font-size: 1rem;
  align-items: start;
}

.optionSubheading {
  color: var(--ion-color-medium);
}

.optionName {
  font-weight: 500;
  color: var(--ion-color-dark);
  margin-bottom: 0.25rem;
}

.originalPrice {
  text-decoration: line-through;
  color: var(--ion-color-tertiary);
  white-space: nowrap;
}

.discountedValue {
  color: var(--ion-color-secondary);
  font-weight: 600;
  white-space: nowrap;
}

