.booking-payment {
  //noinspection ALL
  ion-grid {
    padding-top: 0;
    padding-bottom: 0;
  }

  h4 {
    font-weight: 600;
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    margin: 1rem 0;
  }

  .prices {
    margin-bottom: 1rem;

    .voucher-remaining-balance {
      font-size: 14px;
    }

    & > .price {
      display: flex;
      flex-direction: row;
      margin: 0.25rem 0;

      & > .label {
        display: flex;
        flex: 1;
      }

      & > .value {
        text-align: right;
      }

      &.total {
        margin-top: 1rem;
        font-size: 18px;
        .label {        font-family: "Montserrat", sans-serif;}

      }

      &.discount {
        color: var(--ion-color-secondary);
      }
    }

    & .tax-info {
      align-self: flex-end;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-left: 0.5rem;
    }

    .treatment, .at-home {
      .label {
        font-weight: 400;
      }
    }
  }

  .payment-method {
    ion-list {
      ion-item {
        --padding-start: 0.5rem;
        --background-activated: none;
        flex-basis: 50%;

        & > ion-radio {
          &::part(container) {
            width: 1.5rem;
          }

          &::part(mark) {
            border: 1.5px solid var(--ion-color-tertiary);
            transform: none;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            position: relative;

            &::after {
              display: block;
              content: '';
              width: 1rem;
              height: 1rem;
              position: relative;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              opacity: 0;
              transition: opacity ease-in-out 0.25s;
              background: var(--ion-color-tertiary);
              border-radius: 50%;
            }
          }

          &::part(label) {
            display: flex;
            margin: 0 0 0 1rem;
          }

          &.radio-checked {
            &::part(mark)::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .toc-confirmation {
    font-size: 14px;
    border-top: 1.5px solid var(--ion-color-white);
    --border-color: var(--ion-color-white);
    --padding-top: 1rem;
    --padding-bottom: 1rem;

    .toc-label {
      margin-left: 1rem;
      display: block;
    }

    ion-checkbox {
      margin-left: var(--ion-grid-padding-xs, var(--ion-grid-padding, 10px));
      --checkmark-color: var(--ion-color-tertiary);
      --border-color-checked: var(--ion-color-tertiary);
      --checkbox-background: none;
      --checkbox-background-checked: none;
      --border-radius: 0;
      --border-width: 1.5px;
      --checkmark-width: 3px;
      &::part(label) {
        white-space: normal;
      }
    }
  }

  ion-item.pay-with-referral::part(native) {
    padding-inline-start: 0.4rem;
  }

  .pay-with-referral {
    font-size: 14px;
    --padding-top: 0.5rem;
    --padding-bottom: 0.5rem;

    .toc-label {
      margin-right: 1.5rem;
    }

    ion-checkbox {
      margin-left: 0;
      --checkmark-color: var(--ion-color-tertiary);
      --background: none;
      --background-checked: none;
      --border-radius: 0;
      --border-width: 1.5px;
      --checkmark-width: 3px;
    }
  }
}
