.discountCode {
  margin-bottom: 1rem;

  .removeCodeBtn {
    margin: 0.5rem 0 0;
    padding: 0.5rem;
    --border-width: 1px;
    color: var(--ion-color-secondary);
    background: var(--ion-color-secondary-contrast);
  }
}