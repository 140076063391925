@import '../../../ui/theme/breakpoints';

@mixin svg-icon($icon, $width: 32px, $height: 32px) {
  width: $width;
  height: $height;
  position: relative;
  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
    -webkit-mask: $icon no-repeat 50% 50%;
    mask: $icon no-repeat 50% 50%;
    background-color: var(--ion-color-primary);
    -webkit-mask-size: cover;
    mask-size: cover;
    width: $width;
    height: $height;
    @content;
  }
}

.voucher-selector {
  @include lg {
    font-size: 18px;
    h5 {
      font-size: 25px;
    }
  }

  .form-input {
    border-color: var(--ion-color-tertiary);
    color: var(--ion-color-tertiary);
    --placeholder-color: var(--ion-color-tertiary);
    --placeholder-opacity: 1;
    font-size: 1rem;
  }

  ion-spinner {
    display: block;
    margin: 2rem auto;
  }

  .voucher-header {
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
  }

  .voucher-subtitle {
    font-size: 1rem;
    line-height: 1;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }

  .list-title {
    margin: 0.75rem 0;
  }

  .list {
    margin: 1.125rem 0 0 0;
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      &:before {
        content: "\2606";
        padding-right: 0.5rem;
        color: var(--ion-color-secondary);
      }
    }
  }
}

.voucher-gallery-header {
  height: calc(18rem + env(safe-area-inset-top, 0));
  @include lg {
    height: 100%;
  }
  .default-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

:root {
  --select-border: var(--ion-color-primary);
  --select-focus: var(--ion-color-primary);
}

.voucher-type-selector {
  display: flex;
  justify-content: center;

  .voucher-header {
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
  }

  .voucher-option {
    padding: 0.35rem 0 1.65rem;
    cursor: pointer;
    border-bottom: 1px solid var(--ion-color-medium-shade);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .option-title {
      font-size: 1rem;
      font-weight: 500;
      margin: 0;
      font-family: "Montserrat", sans-serif;
    }

    .promo-badge-wrapper {
      display: flex;
      width: 100%;
    }

    .promo-badge {
      display: inline-block;
     color: var(--ion-color-tertiary);
      background-color: var(--ion-color-primary);
      padding: 0.5rem;
      font-size: 0.75rem;
      font-weight: 500;

    }
  }
}
